
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Text } from '@andes/typography';
import { useI18n } from 'nordic/i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Search16 from '@andes/icons/Search16';
import { Login } from 'op-frontend-commons';
import SearchForm from '../SearchForm';

const HeaderView = (props) => {
  const { i18n } = useI18n();
  const {
    user,
    siteId,
    isMobile,
    originalUrl,
    title,
    baseUrl,
    isScrollable = false,
    // analyticsCode, // TODO - update with amplitude
    initialQueryTerm = '',
    menuItems,
    checkoutView,
  } = props;

  const [isScrollMoreThanHeaderHeight, setScrollMoreThanHeaderHeight] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(true);

  const toggleOpenState = () => {
    const isInputFocused = document.activeElement.tagName === 'INPUT';

    if (isInputFocused || !isMobile) {
      return;
    }

    setIsFormOpen(!isFormOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!isScrollable) return;

      setScrollMoreThanHeaderHeight(window.scrollY > 56);
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const loginComponent = (
    <Login
      label={i18n.gettext('Ingresar')}
      siteId={siteId}
      isMobile={isMobile}
      redirectAfterLogout={originalUrl}
      redirectAfterLogin={originalUrl}
      user={user ? {
        name: user?.raw?.nickname,
        image: user?.raw?.thumbnail?.picture_url,
      } : null}
      textColor={((!isScrollMoreThanHeaderHeight && isScrollable) && !isMobile)
        ? 'rgba(255,255,255, 1)'
        : 'rgba(1, 1, 1, 1)'}
      menuItemsArray={menuItems}
    />);

  const srLabelText = i18n.gettext('Buscar');

  const formDeviceTypeCondition = () => (isMobile ? (
    <button aria-label={srLabelText} className="nav-header-menu-wrapper-mobile" onClick={toggleOpenState}>
      {isFormOpen ? <Search16 /> : <SearchForm baseUrl={baseUrl} initialQueryTerm={initialQueryTerm} isMobile={isMobile} />}
    </button>
  ) : (
    <SearchForm baseUrl={baseUrl} initialQueryTerm={initialQueryTerm} isMobile={isMobile} />
  ));

  return (
    <>
      <header
        role="banner"
        className={
          (!isScrollMoreThanHeaderHeight && isScrollable) ? 'nav-header nav-header-sticky' : 'nav-header nav-header-sticky nav-header--scroll'
        }
      >
        <div className="nav-bounds nav-bounds-large">
          <div className="nav-logo__container">
            <a className={checkoutView ? 'nav-logo nav-logo-mp' : 'nav-logo'} href={baseUrl || '/'} tabIndex="0" title={title}>
              {!checkoutView && (<Text size="l">{i18n.gettext('Centro de Partners')}</Text>)}
            </a>
          </div>

          {!checkoutView && (
            <div className="nav-header-menu-wrapper">
              {formDeviceTypeCondition()}
            </div>
          )}

          {loginComponent}
        </div>
      </header>
    </>
  );
};

HeaderView.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    nickname: PropTypes.string,
    raw: PropTypes.shape({
      internal_tags: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  title: PropTypes.string,
};

export default HeaderView;
